<template>
  <b-card title="Sales Order List ">
    <b-col cols="12">
      <b-row>
        <b-col cols="4">

        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            @input="(value) => doFiltering(value)"
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>

        <b-col style="margin: 15px 0 0 0" cols="12" >
          <b-table
            :items="mappedSales"
            :fields="fields"
            :busy.sync="tableLoading"
            show-empty
            responsive
          >
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item style="text-align:center">
                  <b-button size="sm" @click="detailSO(row)" variant="info" class="mr-1">
                    <feather-icon icon="EyeIcon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import lodash from 'lodash';
import axios from '@/axios';
import {userAccess,dateFormat} from '@/utils/utils'

export default {
  data() {
    return {
      fields: [
        { key: 'rspOrderNumber', label: 'Order Number', filterable: true, sortable: true },
        { key: 'customerPo', label: "Customer PO", filterable: true, sortable: true},
        // { key: 'fullfimentType', label: 'Fullfillment Type', filterable: true, sortable: true },
        { key: 'dealer.dealerCode', label: 'Dealer Code', filterable: true, sortable: true },
        { key: 'sapSo', label: 'Sap So', filterable: true, sortable: true },
        { key: 'orderStatus', label: 'Status', filterable: true, sortable: true, },
        { key: 'deliveryType', label: 'Delivery Type', filterable: true, sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: '',
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      modalDelete: false,
      selectPO:'',
    }
  },
  mounted() {
    this.getItems()
  },
  computed: {
    ...mapGetters({
      sales: 'sales/sales',
      items: 'item/items'
    }),

    mappedSales(){
      return this.sales.map(item => {
        return {
          ...item,
          rspOrderNumber: item.PONumber.includes('*') ? item.PONumber.substring(0, 11) : item.PONumber,
          customerPo: item.PONumber.includes('*') ? item.PONumber.substring(12) : ""
        }
      })
    },

    permission() {
      console.log(userAccess("Order", "order_menu"));
      
      return userAccess("Order", "order_menu");
    },
  },
  methods: {
    ...mapActions({
      getSales: 'sales/getSales',
      testDO: 'delivery/createDO',
      getItems: 'item/getItems',
    }),
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.getSales({ 
        search: (this.search!="")?this.search:undefined,
        page: this.currentPage, 
        entry: this.perPage
        ,orderStatus: 'ALL'
      }).then((data) => this.processMetadata(data.metadata));
    }, 500),
    onPageChange(page) {
      this.currentPage=page;

      this.getSales({ 
        search: (this.search!="")?this.search:undefined,
        page: this.currentPage, 
        entry: this.perPage
        ,orderStatus: 'ALL'
      }).then((data) =>{ 
      console.log("data", data);
      this.processMetadata(data.metadata)});
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    detailSO(row) {
      let idSales = row.item._id;
      this.$router.push({ name: 'sales-order-detail', params: {  idSales: idSales } });
    },
    callDO(row){
      //method for temporary test do module
      //random SAP DO
    let sapDoNumber = Math.floor(Math.random() * 10000);
    let sapDo = `SAPDO-${sapDoNumber}`;

      var doDetails = [];
        row.item.items.forEach(element => {
          let hierarchy = this.items.find(x=>{return x.itemModel === element.model}) //temporary get hierarchy for phier field DO_Details
          console.log('hierarchys', hierarchy)
          console.log('this.items', this.items)
          console.log('row',row)
          doDetails.push({
            lineitem: "1224",
            lineitemref:"1309",
            material: element.model,
            qty: element.qty,
            phier:hierarchy.productHierarchy,
            custgroup:"FU",
          })
        });
      var postal, phone, email, address, address2, address3, city,priority, doType, soType;
      if(row.item.deliveryType==='End Customer'&& row.item.orderType==="DELIVERY"){
        doType="SOEC";
        soType="DEV";
        postal = row.item.customer.postal;
        phone = row.item.customer.phone;
        email = row.item.customer.email;
        address = row.item.customer.address;
        address2 = row.item.customer.address2;
        address3 = row.item.customer.address3;
        city = "Singapore";
      }else if(row.item.deliveryType==='Internal Warehouse Delivery'&& row.item.orderType==="DELIVERY"){
        doType="SOWH";
        soType="DEV";
        postal = row.item.dealer.postalCode;
        phone = row.item.dealer.phone;
        email = row.item.dealer.dealerEmailAddress;
        address = row.item.dealer.billingAddress1.concat(" ",row.item.dealer.billingAddress2);
        address2 = row.item.dealer.dealerName;
        address3 = row.item.timeslot;
        city = "Singapore";
      }else{
        doType="SOSC";
        soType="SELF";
        postal = "638385";
        phone = row.item.dealer.phone; //temporary for Self Collect data from dealer
        email = row.item.dealer.dealerEmailAddress; //temporary for Self Collect data from dealer
        address = "38 Tuas West Rd,";
        address2 = "";
        address3 = "";
        city = "Singapore";
      }

       //format delivery address
        let deliveryAddress1
        if (address.length > 40) {
            deliveryAddress1 = address.substring(0, 40);
        } else {
            deliveryAddress1 = address;
        }

      //format customer name
        let CustomerName1, CustomerName2
        if (row.item.dealer.dealerName.length > 40) {
            CustomerName1 = row.item.dealer.dealerName.substring(0, 40);
            CustomerName2 = row.item.dealer.dealerName.substring(40, row.item.dealer.dealerName.length);
        } else {
            CustomerName1 = row.item.dealer.dealerName;
        }

        /* priority by timeslot list
          'Morning: 9AM - 12PM',
          'Afternoon: 1PM - 3PM',
          'Afternoon: 3PM - 6PM'
        */
      if(row.item.orderType==="SELF_COLLECT"){
        priority='03';
      }else if(row.item.timeslot==="Morning: 9AM - 12PM"){
        priority='11';
      }else if(row.item.timeslot==="Afternoon: 1PM - 3PM"){
        priority='12';
      } else{
        priority='13';
      }

      //date
      var orderDate = moment(row.item.createdAt).format("DDMMYYYY");
      var deliveryDate = moment(row.item.deliveryDate).format("DDMMYYYY");

      let tempDO = {
        plant: "1000", //example : 1000 - DSP or 8000 - DHOS
        shippingPoint: "1003", //example : 1001 or 1003
        sapSo: row.item.sapSo,
        sapDo: sapDo,
        orderDate: orderDate,
        priority:priority,
        deliveryDate: deliveryDate,
        distributionChannel: row.item.dealer.industryType, //example : DL, DR, DRCC, M1
        doType: doType,
        soType: soType,
        sapCreatedBy: "Sales Admin",
        dealerNo : row.item.dealer.dealerCode,
        CustomerName1: CustomerName1,
        CustomerName2: CustomerName2 ? CustomerName2 : "Test buat DO",
        CustomerName3: "",
        CustomerName4: "",
        deliveryCity: city,
        deliveryPostalCode: postal,
        phone: phone,
        email: email,
        status:"New",
        deliveryAddress1: deliveryAddress1, //substring max 40 temporary *address,
        deliveryAddress2: address2,
        deliveryAddress3: address3,
        customerPo: row.item.PONumber,
        salesPersonCode: "C13",
        salesman: "ChoiSoobim",
        projectName: "ActSick",
        pcode: "RSP",
        DO_details: doDetails
      }
      // console.log(row.item);
      // console.log(tempDO);
      this.testDO(tempDO).then(() => {
        this.$bvToast.toast('Delivery Order Created Successfully', {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }).catch((e) => {
        let { response } = e;
        this.$bvToast.toast(response.data.message, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      })
    }
  },
  created() {
    document.title = 'Sales Order | RSP'
    this.getSales({ page:this.currentPage, entry:this.perPage,orderStatus: 'ALL'})
    .then((data) =>{ 
      console.log("data", data);
      this.processMetadata(data.metadata)});
  },
}
</script>
